<template>
  <div>
      <div class="content" :style="cur==index?{backgroundColor:isSkin}:''" @click="cilckMethod">
         <div class="title" :style="cur==index?{color:'#FFF'}:''" >{{item.title}}</div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        cur:{
            type:Number,
            default:0
        },
        index:{
            type:Number,
            default:0
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
    },
    methods:{
      cilckMethod(){
        this.$emit('cilckMethod',{cur:this.cur});
      }
    },
}
</script>

<style scoped lang="scss">
.content{
    width: 285px; 
    margin-top: 10px;
    cursor: pointer;
}
.title{
    margin-left: 32px;
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    color: #333;
}
</style>