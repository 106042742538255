<template>
   <div class="main">
      <div class="content-view">
        <!-- 头 -->
        <div class="top" :style="{backgroundColor:isSkin}">
            <div class="thumb-title">书目下载</div>
            <div class="sousuo-view" @click.stop="sousuoMethod()">
                <div class="sm-title">书目搜索</div>
                <div>
                    <img src="../../../assets/home/sousuo-bai.png" alt="">
                </div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="list-view">
            <div class="list-left">
                <leftItem v-for="(item,index) in list" :key="index" :index="cur" :cur="index" :item="item" @cilckMethod="cilckMethod"></leftItem>
            </div>
            <div class="list-right">
                <downlaodList v-for="(item,index) in list1" :key="index" :item="item"></downlaodList>
                <div class="page" v-show="pageShow">
                    <div class="page1">
                        <el-pagination background :current-page="currentPage"
                        :page-size="1" layout="prev, pager, next" :total="totals" @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
      </div>
   </div>
</template>

<script>
import leftItem from '../../aboutUs/components/leftItem-box'
import downlaodList from './components/downloadList-box'
export default {
    components:{leftItem,downlaodList},
    data() {
        return {
            pageShow:false,
            totals:1,
            currentPage:1,
            pageSize:5,
            category:'',
            cur:0,
            list:[],
            listCode:[],
            list1:[]
        }
    },
    created(){
        this.$store.commit('changClick',1)
        let skin = sessionStorage.getItem('Skin')
        let zskin = sessionStorage.getItem('ZSkin')
        if (skin) {
            this.$store.commit('changSkin',skin)
        }
        if (zskin) {
            this.$store.commit('changZSkin',zskin)
        }
        this.handleCurrentChange(1)
        this.getContentsCategory()
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
    },
    methods:{
        getContentsCategory(){
          let that = this
          that.$api.getContentsCategory({}).then(res=>{
            if (res.data.code == 0){
              that.list.push({
                title:'全部'
              })
              that.listCode.push('')
              res.data.data.forEach((item,index)=>{
                that.list.push({
                  title:item.dictLabel
                })
                that.listCode.push(item.dictValue)
              })
            }else {
              that.list = []
              that.listCode = []
            }
          })
        },
        getBookListContents(){
          let that = this
          that.$api.getBookListContents({
            params:{
              category:that.category,
              name:'',
              pageNum:that.currentPage,
              pageSize:that.pageSize
            }
          }).then(res=>{
            if (res.data.code == 0){
                that.totals = res.data.data.pages
                that.list1 = res.data.data.list
                if (res.data.data.list.length == 0){
					that.pageShow = false
			    }else {
					that.pageShow = true
				}
            }else {
                that.list1 = []
                that.pageShow = false
            }
          })
        },
        cilckMethod(index){
            this.cur = index.cur
            this.category = this.listCode[index.cur]
            this.getBookListContents()
        },
        /**当前页按钮**/ 
        handleCurrentChange(index) {
            console.log(index);
            this.currentPage = index;
            this.getBookListContents()
            this.$nextTick(function () {
                /**更换分页按钮皮肤**/
                var array = document.querySelectorAll(".el-pagination.is-background .el-pager li"); 
                if(array && array.length>0){
                array.forEach((item,index1) => {
                    if (index == array[index1].innerText) {
                    array[index1].style.background  = this.isSkin;
                    }else{
                    array[index1].style.background  = "#f4f4f5";
                    }
                })
                }
            });
        },
        sousuoMethod(){
            this.$router.push('/bookRecordSearch');
            this.$store.commit('changClick',1)
        }
    }
}
</script>

<style scoped lang="scss">
.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content-view{
        width: 1200px;
        padding-top: 50px;
        .top{
            width: 100%;
            height: 55px;
            border-radius: 4px;
            line-height: 55px;
            display: flex;
            justify-items: center;
            align-items: center;
            position: relative;
            .thumb-title{
                font-size: 18px;
                color: #FFFFFF;
                margin-left: 12px;
            }
            .sousuo-view{
                position: absolute;
                right: 20px;
                display: flex;
                justify-items: center;
                align-items: center;
                width: 100px;
                cursor: pointer;
                > div img{
                    display: flex;
                    justify-items: center;
                    align-items: center;
                }
                .sm-title{
                    font-size: 18px;
                    line-height: 25px;
                    color: #FFFFFF;
                    padding-right: 10px;
                }
            }
        }
        .list-view{
            width: 100%;
            display: flex;
            justify-items: center;
            align-items: flex-start;
            .list-left{
                width: 285px;
                background: #FCFCFC;
                padding: 0px 0px 10px 0px;
                margin-top: 20px;
            }
            .list-right{
                width: 895px;
                margin-left: 20px;
                margin-bottom: 40px;
                .page{
                    width: 896px;
                    padding: 40px 20px 100px 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .page1{
                        display: flex;
                        justify-items: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}
</style>